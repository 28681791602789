@tailwind base;
@tailwind components;
@tailwind utilities;

/* If you add a color class here, don't forget to add it to the tailwind.config.js file as well. */
.text-blueDark {
  color: #004cb8;
}

.text-blueLight {
  color: #81d4fb;
}

.text-blueMedium {
  color: #009adc;
}

.text-greyDarker {
  color: #3a3a3a;
}

.text-greyDark {
  color: #868694;
}

.text-greyLight {
  color: #f2f2f2;
}

.text-greyMedium {
  color: #c2c2d1;
}

.text-greenDark {
  color: #31d0aa;
}

.text-greenLight {
  color: #94db42;
}

.text-pink {
  color: #ff41ae;
}

.text-pinkLight {
  color: #ffa0d6;
}

.text-red {
  color: #e13364;
}

.text-yellow {
  color: #fec907;
}

.text-yellowLight {
  color: #ffeca9;
}

.text-white {
  color: #ffffff;
}
